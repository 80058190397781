body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

td.addressCell,
th.addressHeader {
  border: 1px solid #ddd;
}

table.addressTable {
  border-collapse: collapse;
  width: 100%;
}

tr.addressRow:nth-child(even) {
  background-color: #f2f2f2;
}

tr.addressRow:hover {
  background-color: #ddd;
}

th.addressHeader {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4caf50;
  color: white;
}
